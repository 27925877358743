import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Observable, BehaviorSubject } from 'rxjs';

import { AlertService } from '@core/alert/alert.service';
import { IPolicy, SubscriptionState } from '@core/models';
import { UnsubscribePolicyModalComponent } from '@shared/components/modals/unsubscribe-policy-modal/unsubscribe-policy-modal.component';
import { CatalogPolicySubscriptionComponent } from '@shared/components/modals/catalog-policy-subscription/catalog-policy-subscription.component';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PolicySubscriptionService {
  modalRef: NgbModalRef;
  subscriptionState = SubscriptionState;
  private subscriptionChangeSource = new BehaviorSubject<string>(null);
  subscriptionChange$ = this.subscriptionChangeSource.asObservable();

  constructor(
    private alertService: AlertService,
    private http: HttpClient,
    protected modalService: NgbModal,
    private translateService: TranslateService
  ) {}

  openSubscribeModal = (policy: IPolicy) => {
    if (policy.subscriptionState === this.subscriptionState.PENDING) {
      return;
    }
    this.modalRef = this.modalService.open(CatalogPolicySubscriptionComponent);
    this.modalRef.componentInstance.policy = policy;
    this.modalRef.componentInstance.mainClick.subscribe(this.subscribe);
  };

  openUnsubscribeModal = (policy: IPolicy) => {
    this.modalRef = this.modalService.open(UnsubscribePolicyModalComponent);
    this.modalRef.componentInstance.policy = policy;
    this.modalRef.componentInstance.mainClick.subscribe(this.unsubscribe);
  };

  policySubscribe = (policy: IPolicy): Observable<string> => {
    const { id } = policy;
    const url = environment.SUBSCRIBEPOLICY.replace('{policyId}', id);
    return this.http.post(url, {}, { responseType: 'text' });
  };

  policyUnsubscribe = (policy: IPolicy): Observable<string> => {
    const { id } = policy;
    const url = environment.UNSUBSCRIBEPOLICY.replace('{policyId}', id);
    return this.http.post(url, {}, { responseType: 'text' });
  };

  subscribe = (policy: IPolicy): void => {
    this.policySubscribe(policy).subscribe(() => {
      this.modalRef.close();
      this.modalRef = null;
      const message = `${this.translateService.instant('catalog-details.Subscription successful.')}`;
      this.alertService.success(message);
      this.subscriptionChangeSource.next(policy.id);
    });
  };

  unsubscribe = (policy: IPolicy): void => {
    this.policyUnsubscribe(policy).subscribe(() => {
      this.modalRef.close();
      this.modalRef = null;
      const message = `${this.translateService.instant('Your subscription has been ended.')}`;
      this.alertService.success(message);
      this.subscriptionChangeSource.next(policy.id);
    });
  };
}
