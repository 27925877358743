import { IAdvancedRule } from '@advanced-rules/advanced-rule.model';
import { CampaignStatus } from '../core/models/status-campaign';
import { IConsentSurvey } from './consent-policy/consent-survey.model';
import { ProtectionLevel } from '@core/models/protection-level';
import { ImageFitType } from '@core/models/image-fit-types';
import { IExpirationTime } from '@shared/features/custom-forms/expirationTime';
import { ICustomStyles } from '@features/custom-styles/custom-styles.model';

export interface IConsentReward {
  description: string;
  quantity: string;
}

export enum ContactType {
  Email = 'Email',
  Trigger = 'Trigger',
}

export enum CampaignType {
  Dataset = 'Dataset',
  Survey = 'Survey',
}

export interface ICampaignWebhook {
  campaignId: string;
  webhookId: string;
  name: string;
}

export interface IConsentDataSet {
  consentedDataOwners?: number;
  datasetId: string;
  name?: string;
  columnNameEmailOwner: string;
  payload: { rules: IAdvancedRule[] };
  rewards: IConsentReward[];
  showMetaData?: boolean;
  tokenType?: any;
  tokenQuantity?: any;
}

export interface IConsentCampaign extends ICustomStyles {
  id?: string;
  title: string;
  image?: File;
  imageFit: ImageFitType;
  imageName?: string;
  reviewer?: string;
  protectionLevel?: ProtectionLevel;
  dataUsageDescription?: string;
  dataUsageDuration?: IExpirationTime;
  datasets?: IConsentDataSet[];
  status?: CampaignStatus;
  organizationId?: string;
  survey?: IConsentSurvey;
  surveyContent?: string;
  surveyVersion?: string;
  contactType: ContactType;
  campaignType: CampaignType;
  campaignWebhooks?: ICampaignWebhook[];
  hideTransparencyText: boolean;
  daysBeforeExpire?: number;
  expireTime?: string;
  originalCampaignId?: string;
  isExtended?: boolean;
}
