import { Validators } from '@angular/forms';

import { Observable, Subject } from 'rxjs';

import { IBucketFile } from '@core/models/bucketFile.model';
import { IDataSource } from '@core/models/data-source.model';
import { IDatasetBasicInfo } from './dataset-basic-info.model';
import { DatasetTypes } from './dataset-types';
import { defaultDBConnection, getDBConnectionForm } from './db-connection-helpers';

export abstract class DatasetsAbstractService {
  fileReadySource = new Subject<any>();
  fileReady$ = this.fileReadySource.asObservable();

  cachedDatasources$: Observable<IDataSource[]>;

  MIMETypes = {
    ['xlsx']: 'application/vnd.ms-excel',
    ['pdf']: 'application/pdf',
  };

  formConfig = {
    [DatasetTypes.upload]: (data = this.defaultBasicInfo, file = '') => {
      return {
        ...this.getBasicInfoForm(data),
        ...this.getUploadForm(file),
      };
    },
    [DatasetTypes['db-connect']]: (data = { ...defaultDBConnection(), ...this.defaultBasicInfo }) => {
      return {
        ...getDBConnectionForm(data),
        ...this.getBasicInfoForm(data),
      };
    },
    [DatasetTypes.aggregate]: (data = this.defaultBasicInfo) => {
      return {
        ...this.getAggregationForm(),
        ...this.getBasicInfoForm(data),
      };
    },
  };

  get defaultBasicInfo(): IDatasetBasicInfo {
    return {
      description: '',
      name: '',
      image: null,
    };
  }

  addOwned = (datasource: IDataSource, actAs: string[]): IDataSource => {
    return { ...datasource, owned: actAs.includes(datasource.publisher) };
  };

  abstract downloadS3File(bucketFile: IBucketFile): Observable<any>;

  getAggregationForm = () => {
    return {
      source: ['EXCEL'],
      isAggregation: [true, Validators.required],
      datasourceIds: [[]],
      aggregationDatasourceKeys: ['', Validators.required],
    };
  };

  getBasicInfoForm(basicInfo = this.defaultBasicInfo) {
    const { description, name, image } = basicInfo;
    return {
      description: [description],
      name: [name, Validators.required],
      image: [image],
    };
  }

  getUploadForm(file = null) {
    return {
      file: [file, Validators.required],
    };
  }

  getDefaultFields(path = 'Root') {
    return [
      {
        format: '',
        level: 0,
        path: [path],
        type: 'folder',
      },
    ];
  }

  getDatasourcesWithOwner = (datasources: IDataSource[], actAs: string[]): IDataSource[] => {
    return datasources.map(datasource => {
      return this.addOwned(datasource, actAs);
    });
  };

  abstract getFileFields(file: File): Observable<any>;
}
