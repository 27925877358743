import { Validators } from '@angular/forms';

import { IDBConnection, DBTypes } from '@core/models';
import { procedureValidator } from '@features/db-connection/db-table-manager/procedure-validators';

export const defaultDBConnection = (): IDBConnection => {
  return {
    dbType: null,
    dbName: '',
    dbHost: '',
    dbPort: '',
    dbUsername: '',
    dbPassword: '',
    dbTable: '',
    dbWarehouse: '',
    dbRole: '',
    dbSchema: '',
    dbHttpPath: '',
    awsAccessKeyId: '',
    awsSecretAccessKey: '',
    bucketName: '',
    filterPattern: '',
    region: '',
    creationUser: '',
    templateName: '',
    tenantId: '',
    secretValue: '',
    applicationId: '',
    siteId: '',
    driveItemId: '',
    driveItemName: '',
    dbStoredProcedure: '',
    dbStoredProcedureParameters: null,
  };
};

export const getDBConnectionForm = (dbconn = defaultDBConnection()) => {
  const conf = {};
  Object.keys(dbconn).forEach(key => {
    conf[key] = [dbconn[key]];
  });
  conf['dbTable'] = [{ value: dbconn.dbTable, disabled: true }, Validators.required];
  if (dbconn.dbType) {
    const dbTypeConf = getDBTypeConf(dbconn);
    Object.keys(dbTypeConf).forEach(item => {
      conf[item] = dbTypeConf[item];
    });
  }
  return conf;
};

export const getDBTypeConf = (dbConn: IDBConnection) => {
  const { dbType, dbTable, dbStoredProcedure, dbStoredProcedureParameters, siteId, driveItemId, driveItemName } = dbConn;
  let conf;
  if (dbType === DBTypes.Sharepoint) {
    conf = {
      dbTable: [dbTable],
      dbStoredProcedure: [dbStoredProcedure],
      dbStoredProcedureParameters: [dbStoredProcedureParameters],
      siteId: [siteId, Validators.required],
      driveItemId: [driveItemId, Validators.required],
      driveItemName: [driveItemName],
    };
  } else {
    conf = {
      dbTable: [dbTable, Validators.required],
      dbStoredProcedure: [dbStoredProcedure],
      dbStoredProcedureParameters: [dbStoredProcedureParameters],
      siteId: [siteId],
      driveItemId: [driveItemId],
    };
  }

  return conf;
};

export const getValidators = (dbType: DBTypes) => {
  if (dbType === DBTypes.Kx) {
    return [procedureValidator];
  } else {
    return [];
  }
};
