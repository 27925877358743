<ul class="sidebar">
  <li class="">
    <a class="logo" [routerLink]="['/dashboard']">&nbsp;</a>
  </li>
  <li class="sidebar__add">
    <ng-container *ngIf="(isPlatform$ | async) || (isViewer | async); else platformTemplate">
      <button type="button" class="icon-btn" [disabled]="true">
        <fa-icon [icon]="faPlus"></fa-icon>
      </button>
    </ng-container>
    <ng-template #platformTemplate>
      <app-create-dataset-menu>
        <button type="button" class="icon-btn">
          <fa-icon [icon]="faPlus"></fa-icon>
        </button>
      </app-create-dataset-menu>
    </ng-template>
  </li>
  <li class="sidebar__item" [ngClass]="{ 'sidebar__item--disabled': isPlatform$ | async }" [routerLinkActive]="'sidebar__item--active'">
    <a [routerLink]="(isPlatform$ | async) ? null : ['/dashboard']">
      <fa-icon [icon]="faHome"></fa-icon>
      <div class="sidebar__text">{{ 'Home' | translate }}</div>
    </a>
  </li>

  <li class="sidebar__item" [ngClass]="{ 'sidebar__item--disabled': isPlatform$ | async }" [routerLinkActive]="'sidebar__item--active'">
    <a [routerLink]="(isPlatform$ | async) ? null : ['/subscriptions']">
      <fa-icon [icon]="faChartSimple"></fa-icon>
      <div class="sidebar__text">{{ 'Analytics' | translate }}</div>
    </a>
  </li>
  <ng-container *ngIf="!(isPlatform$ | async) && ((isNonViewer | async) || (isAdmin | async))">
    <li class="sidebar__item" [routerLinkActive]="'sidebar__item--active'">
      <button type="button">
        <fa-icon [icon]="faCog"></fa-icon>
        <div class="sidebar__colapse">
          <div class="sidebar__text">{{ 'Settings' | translate }}</div>
        </div>
      </button>
      <ul>
        <ng-container *ngIf="isAdmin | async">
          <li class="sidebar__item" [routerLinkActive]="'sidebar__item--active'">
            <a [routerLink]="['/admin/billing']">{{ 'Billing' | translate }}</a>
          </li>
          <li class="sidebar__item" [routerLinkActive]="'sidebar__item--active'">
            <a [routerLink]="['/admin/users']">{{ 'Users' | translate }}</a>
          </li>
        </ng-container>

        <li class="sidebar__item" [routerLinkActive]="'sidebar__item--active'">
          <a [routerLink]="['/admin/contacts']">{{ 'Contacts' | translate }}</a>
        </li>

        <li class="sidebar__item" [routerLinkActive]="'sidebar__item--active'">
          <a [routerLink]="['/admin/preferences']">{{ 'Preference Wallet' | translate }}</a>
        </li>

        <li class="sidebar__item" [routerLinkActive]="'sidebar__item--active'">
          <a [routerLink]="['/admin/integrations']">{{ 'Integrations' | translate }}</a>
        </li>
      </ul>
    </li>
  </ng-container>
  <ng-template #noAdmin>
    <li class="sidebar__item sidebar__item--disabled">
      <a>
        <fa-icon [icon]="faCog"></fa-icon>
        <div class="sidebar__colapse">
          <div class="sidebar__text">{{ 'Settings' | translate }}</div>
        </div>
      </a>
    </li>
  </ng-template>
  <li
    class="sidebar__item"
    [ngClass]="{ 'sidebar__item--disabled': isPlatform$ | async }"
    [routerLinkActiveOptions]="{ exact: true }"
    [routerLinkActive]="'sidebar__item--active'"
  >
    <a [routerLink]="(isPlatform$ | async) ? null : ['/policies/policy-viewer']">
      <span class="oc-icon oc-icon--eye"></span>
      <div class="sidebar__text">{{ 'Decrypt' | translate }}</div>
    </a>
  </li>
  <li class="sidebar__item" [routerLinkActive]="'sidebar__item--active'">
    <a [routerLink]="['/help']">
      <fa-icon [icon]="faQuestionCircle"></fa-icon>
    </a>
  </li>
</ul>
