import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs/internal/Observable';
import { Subject } from 'rxjs';
import { share, tap } from 'rxjs/operators';

import { IDataSource } from '@core/models/data-source.model';
import { IPagination } from '@core/models/pagination-params';
import { getPaginationParams } from '@shared/helpers/helpers';
import { environment } from 'src/environments/environment';
import { IPaginationResponse } from '@core/models/pagination-response.model';

@Injectable({
  providedIn: 'root',
})
export class DataSourcesService {
  private datasourceChange = new Subject<string>();
  datasourceChange$ = this.datasourceChange.asObservable();

  constructor(private httpClient: HttpClient) {}

  deleteDatasource = (id: string): Observable<string> => {
    const url = environment.DATASOURCE.replace('{datasourceId}', id);
    return this.httpClient.delete(url, { responseType: 'text' });
  };

  downloadContribution = (datasourceId: string, contributionId: string, fileName: string): Observable<any> => {
    const url = environment.DATASOURCESDOWNLOADCONTRIBUTION.replace('{datasourceId}', datasourceId)
      .replace('{contributionId}', contributionId)
      .replace('{fileName}', fileName);
    return this.httpClient.get(url, { responseType: 'arraybuffer' });
  };

  getDataSource = (id: string): Observable<IDataSource> => {
    const url = environment.DATASOURCE.replace('{datasourceId}', id);
    return this.httpClient.get<IDataSource>(url).pipe(share());
  };

  getDataSources(params?: IPagination, options = {}): Observable<IPaginationResponse<IDataSource>> {
    const url = environment.DATASOURCES;
    return this.httpClient.get<IPaginationResponse<IDataSource>>(`${url}${getPaginationParams(params)}`, options);
  }

  rejectDatasource = (id: string): Observable<any> => {
    const url = environment.REJECTDATASOURCE.replace('{datasourceId}', id);
    return this.httpClient.post(`${url}`, {}, { responseType: 'text' }).pipe(tap(() => this.datasourceChange.next(id)));
  };
}
