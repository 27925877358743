import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { catchError, concatMap, map, shareReplay, tap } from 'rxjs/operators';
import { Observable, of, Subject } from 'rxjs';

import { IConsentCampaignItem } from '@consent/consent-campaign-item.model';
import { AuthService } from '@core/auth/auth.service';
import { IPaginationResponse } from '@core/models/pagination-response.model';
import { IBucketFile } from '@core/models/bucketFile.model';
import { IDataSource } from '@core/models/data-source.model';
import { IPagination } from '@core/models/pagination-params';
import { DatasetsAbstractService } from './datasets.abstract.service';
import { GridService } from '@features/oc-grid/grid.service';
import { environment } from 'src/environments/environment';
import { getPaginationParams, getFileExtension } from '@shared/helpers/helpers';
import { ICustomFormOption } from '@shared/features/custom-forms/custom-form-option.model';
import { RecipientsService } from '@shared/services/recipients.service';
import { OcUserService } from '@shared/services/oc-user.service';

@Injectable({
  providedIn: 'root',
})
export class DatasetsService extends DatasetsAbstractService {
  private datasourceChange = new Subject<string | void>();
  datasourceChange$ = this.datasourceChange.asObservable();

  constructor(
    protected authService: AuthService,
    protected domSanitizer: DomSanitizer,
    protected http: HttpClient,
    protected gridService: GridService,
    protected ocUserService: OcUserService,
    protected recipientsService: RecipientsService
  ) {
    super();
  }

  get userOrganizationId$(): Observable<string> {
    return this.ocUserService.getUserId();
  }

  addDatasetImage = (datasource: IDataSource): IDataSource => {
    const { image } = datasource;
    return image ? { ...datasource, image: this.domSanitizer.bypassSecurityTrustUrl(`data:image/;base64,${image}`) } : datasource;
  };

  buildDataset = (datasource: IDataSource, actAs: string[]): IDataSource => {
    const datasetWithOwner = this.addOwned(datasource, actAs);
    return this.addDatasetImage(datasetWithOwner);
  };

  buildDatasets = (datasources: IDataSource[], actAs: string[]): IDataSource[] => {
    return datasources.map(datasource => {
      return this.buildDataset(datasource, actAs);
    });
  };

  deleteDatasource = (id: string): Observable<string> => {
    const url = environment.DATASOURCEDELETE.replace('{datasourceId}', id);
    return this.http.delete(url, { responseType: 'text' }).pipe(tap(() => this.datasourceChange.next(id)));
  };

  downloadS3File = (bucketFile: IBucketFile): Observable<any> =>
    this.http.post(environment.DOWNLOADS3FILES, bucketFile, { responseType: 'arraybuffer' });

  getDatasource = (datasourceId: string): Observable<IDataSource> => {
    const url = environment.DATASOURCE.replace('{datasourceId}', datasourceId);
    return this.http.get<IDataSource>(url);
  };

  getDatasources = (params?: IPagination, options = {}): Observable<IPaginationResponse<IDataSource>> => {
    const url = environment.DATASOURCES;
    return this.http.get<IPaginationResponse<IDataSource>>(`${url}${getPaginationParams(params)}`, options);
  };

  getDatasourcesOptions = (): Observable<ICustomFormOption<string>[]> => {
    return this.getCachedDatasets().pipe(
      map(response => {
        return response
          .filter(datasources => datasources.fields?.length > 1)
          .map(datasource => {
            return { value: datasource.id, label: datasource.name };
          });
      })
    );
  };

  getDataset = (datasourceId: string) => {
    const actAs$ = this.authService.userActAs$;
    return actAs$.pipe(
      concatMap(actAs => {
        return this.getDatasource(datasourceId).pipe(
          map(datasource => {
            return this.buildDataset(datasource, actAs);
          })
        );
      })
    );
  };

  getDatasetConsentCampaigns = (datasetId: string): Observable<IConsentCampaignItem[]> => {
    const url = environment.CONSENTCAMPAIGNSLIST.replace('{datasetId}', datasetId);
    return this.http.get<IConsentCampaignItem[]>(url);
  };

  getDatasets = (params?: IPagination, options = {}): Observable<IPaginationResponse<IDataSource>> => {
    const actAs$ = this.authService.userActAs$;
    return actAs$.pipe(
      concatMap(actAs => {
        return this.getDatasources(params, options).pipe(
          map(response => {
            const { list, totalPages } = response;
            return { list: list ? this.buildDatasets(response.list, actAs) : [], totalPages };
          }),
          catchError(() => {
            return of({ list: [], totalPages: 0 });
          })
        );
      })
    );
  };

  getCachedDatasets = (params?: IPagination, options = {}): Observable<IDataSource[]> => {
    if (!this.cachedDatasources$) {
      const actAs$ = this.authService.userActAs$;
      this.cachedDatasources$ = actAs$.pipe(
        concatMap(actAs => {
          return this.getDatasources(params, options).pipe(
            map(response => {
              return response?.list ? this.buildDatasets(response.list, actAs) : [];
            }),
            catchError(() => {
              return of([]);
            })
          );
        }),
        shareReplay()
      );
    }
    return this.cachedDatasources$;
  };

  getFileFields(file: File): Observable<any> {
    if (getFileExtension(file.name) === 'pdf') {
      return of(this.getDefaultFields());
    } else {
      const reader = new FileReader();
      return new Observable(observer => {
        reader.onload = () => {
          const data = reader.result;
          const levels = this.gridService.getLevels(data);
          observer.next(levels);
        };
        reader.readAsBinaryString(file);
      });
    }
  }

  resetDatasourcesCache = (): void => {
    this.cachedDatasources$ = null;
  };
}
