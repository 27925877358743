import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { NgIdleModule } from '@ng-idle/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
import { TranslateHttpLoader } from './custom-translate-loader';
import { HomeLayoutComponent } from './layouts/home-layout.component';
import { LandingLayoutComponent } from './layouts/landing-layout.component';
import { NotificationCenterModule } from './notification-center/notification-center.module';

@NgModule({
  declarations: [AppComponent, HomeLayoutComponent, LandingLayoutComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    // ngx-translate and the loader module
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      isolate: false,
    }),
    AppRoutingModule,
    NotificationCenterModule,
    NgIdleModule.forRoot(),
  ],
  providers: [{ provide: Window, useValue: window }],
  bootstrap: [AppComponent],
})
export class AppModule {}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
