import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';

import { AuthService } from '@core/auth/auth.service';
import { IOCUser, IOrganization } from '@core/models';
import { NavigationService } from '@core/services/navigation.service';
import { MyAccountComponent } from '@shared/components/modals/my-account/my-account.component';
import { environment } from 'src/environments/environment';
import { OcUserService } from '@shared/services/oc-user.service';

@Component({ template: '' })
export class BaseLayoutComponent implements OnInit {
  encryptionKey: string;
  modalRef: NgbModalRef;
  subscriptions = new Subscription();
  user: IOCUser = null;
  userOrganization: IOrganization;
  constructor(
    protected authService: AuthService,
    protected modalService: NgbModal,
    protected navigationService: NavigationService,
    protected router: Router,
    protected ocUserService: OcUserService,
    protected window: Window
  ) {}

  ngOnInit(): void {
    this.getUser();
    this.getUserOrganization();
    this.getEncryptionKey();
    this.getAccountModalStatus();
    this.reloadOnOrganizationChange();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  getAccountModalStatus = (): void => {
    this.subscriptions.add(this.navigationService.accountClicked$.subscribe(this.openMyAccount));
  };

  getEncryptionKey = (): void => {
    this.subscriptions.add(this.ocUserService.getEncryptionKey().subscribe(key => (this.encryptionKey = key)));
  };

  getUser = (): void => {
    this.subscriptions.add(this.ocUserService.getUser().subscribe(account => (this.user = account)));
  };

  getUserOrganization = (): void => {
    this.subscriptions.add(this.ocUserService.getUserOrganization().subscribe(organization => (this.userOrganization = organization)));
  };

  logout = (): void => {
    const { AUTHCONF } = environment;
    this.authService.logout({ client_id: AUTHCONF.clientId, returnTo: AUTHCONF.returnTo });
  };

  openMyAccount = (): void => {
    this.modalRef = this.modalService.open(MyAccountComponent, { size: 'lg' });
    const { userOrganizations, email } = this.user;
    const { name, organizationId } = this.userOrganization;
    this.modalRef.componentInstance.accountData = {
      email,
      userName: userOrganizations[0].userName,
      organizationId,
      organizationName: name,
      userEntitlements: userOrganizations[0].entitlementRoles.roles,
    };
    this.modalRef.componentInstance.encryptionKey = this.encryptionKey;
  };

  reloadOnOrganizationChange = (): void => {
    this.subscriptions.add(
      this.ocUserService.userOrganizationChange$.subscribe(() => {
        this.router.navigate(['/']).then(() => {
          this.window.location.reload();
        });
      })
    );
  };
}
