import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { CatalogService } from '../catalog/catalog.service';
import { ConsentPolicyService } from '@consent/consent-policy/consent-policy.service';
import { IPolicy } from '@core/models';
import { RequestService } from '@core/services/request.service';
import { PolicyService } from '@shared/services/policy.service';
import { DataSourcesService } from '@shared/services/source-data.service';
import { SubscriptionsService } from '@shared/services/subscriptions.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationCenterService {
  private showNotificationsSource = new BehaviorSubject<boolean>(false);
  private showNotifications = false;

  constructor(
    private policyService: PolicyService,
    private dataSourcesService: DataSourcesService,
    private catalogService: CatalogService,
    private consentPolicyService: ConsentPolicyService,
    private requestService: RequestService,
    private subscriptionsService: SubscriptionsService
  ) {}

  closeNotifications = (): void => {
    this.showNotifications = false;
    this.showNotificationsSource.next(this.showNotifications);
  };

  getStateNotifications() {
    return this.showNotificationsSource.asObservable();
  }

  setStateNotifications = () => {
    this.showNotifications = !this.showNotifications;
    this.showNotificationsSource.next(this.showNotifications);
  };

  // DATASOURCES

  rejectDatasource = (datasourceId, requestId) =>
    this.dataSourcesService.rejectDatasource(datasourceId).pipe(tap(() => this.requestService.deleteRequest(requestId)));

  // POLICIES
  approvePolicy = (policyId, requestId) =>
    this.policyService.approvePolicy(policyId).pipe(tap(() => this.requestService.deleteRequest(requestId)));
  approveEditPolicy = (policyId, requestId) =>
    this.policyService.approveEditPolicy(policyId).pipe(tap(() => this.requestService.deleteRequest(requestId)));
  approveDelistPolicy = (policyId, requestId) =>
    this.policyService.approveDelistPolicy(policyId).pipe(tap(() => this.requestService.deleteRequest(requestId)));
  rejectPolicy = (policyId, requestId) =>
    this.policyService.rejectPolicy(policyId).pipe(tap(() => this.requestService.deleteRequest(requestId)));
  rejectEditPolicy = (policyId, requestId) =>
    this.policyService.rejectEditPolicy(policyId).pipe(tap(() => this.requestService.deleteRequest(requestId)));
  rejectDelistPolicy = (policyId, requestId) =>
    this.policyService.rejectDelistPolicy(policyId).pipe(tap(() => this.requestService.deleteRequest(requestId)));

  // SUBSCRIPTIONS
  aproveSubscription = (subscriptionId, userFrom, requestId) =>
    this.subscriptionsService.aproveSubscription(subscriptionId, userFrom).pipe(tap(() => this.requestService.deleteRequest(requestId)));
  rejectSubscription = (subscriptionId, userFrom, requestId) =>
    this.catalogService.rejectSubscription(subscriptionId, userFrom).pipe(tap(() => this.requestService.deleteRequest(requestId)));

  policyUnsubscribe = (policy: IPolicy) => this.catalogService.policyUnsubscribe(policy);

  // CONSENT CAMPAIGN

  consentCampaignApprove = (campaignId, requestId): Observable<unknown> =>
    this.consentPolicyService.consentCampaignApprove(campaignId).pipe(tap(() => this.requestService.deleteRequest(requestId)));

  consentCampaignReject = (campaignId, requestId): Observable<unknown> =>
    this.consentPolicyService.consentCampaignReject(campaignId).pipe(tap(() => this.requestService.deleteRequest(requestId)));
}
