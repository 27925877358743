import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import {
  faHome,
  faUsers,
  faChartSimple,
  faQuestionCircle,
  faFileInvoiceDollar,
  faList,
  faPlus,
  faCog,
} from '@fortawesome/free-solid-svg-icons';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Role } from '@core/models';
import { OcUserService } from '@shared/services/oc-user.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarComponent implements OnInit {
  faQuestionCircle = faQuestionCircle;
  faHome = faHome;
  faFileInvoiceDollar = faFileInvoiceDollar;
  faUsers = faUsers;
  faCog = faCog;
  faChartSimple = faChartSimple;
  faList = faList;
  faPlus = faPlus;

  roles$: Observable<Role[]>;
  isPlatform$: Observable<boolean>;

  constructor(protected ocUserService: OcUserService) {}

  ngOnInit(): void {
    this.isPlatform$ = this.ocUserService.isPlatformUser();
  }

  get isAdmin(): Observable<boolean> {
    return this.ocUserService.getUserOrganizationEntitlements().pipe(
      map(entitlements => {
        return entitlements.includes('ADMIN');
      })
    );
  }

  get isNonViewer(): Observable<boolean> {
    return this.ocUserService.getUserOrganizationEntitlements().pipe(
      map(entitlements => {
        return entitlements.includes('EDITOR') || entitlements.includes('REVIEWER');
      })
    );
  }

  get isViewer(): Observable<boolean> {
    return this.ocUserService.getUserOrganizationEntitlements().pipe(
      map(entitlements => {
        return (
          entitlements.includes('VIEWER') &&
          !entitlements.includes('REVIEWER') &&
          !entitlements.includes('ADMIN') &&
          !entitlements.includes('EDITOR')
        );
      })
    );
  }
}
