import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription, Observable } from 'rxjs';

import moment from 'moment';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

import { RequestService } from '@core/services/request.service';
import { AlertService } from '@core/alert/alert.service';
import { AuthService } from '@core/auth/auth.service';
import { IPolicy } from '@core/models';
import { PolicyStatus } from '@core/models/policy-status';
import { IDataSource } from '@core/models/data-source.model';
import { INotification } from '@core/models/notification.model';
import { NotificationType } from '@core/models/notification-type';
import { INotificationMessage } from '@core/models/notification-message.model';
import { Status } from '@core/models/status';
import { ActionType, INotificationAction } from './notification-action.model';
import { NotificationCenterService } from './notification-center.service';
import { UnsubscribePolicyModalComponent } from '@shared/components/modals/unsubscribe-policy-modal/unsubscribe-policy-modal.component';

@Component({
  selector: 'app-notification-center',
  templateUrl: './notification-center-component.html',
  styleUrls: ['./notification-center-component.scss'],
  animations: [
    trigger('openClose', [
      state('true', style({ opacity: 1 })),
      state('false', style({ opacity: 0, display: 'none' })),
      transition('false <=> true', [style({ display: 'block' }), animate(200)]),
    ]),
  ],
})
export class NotificationCenterComponent implements OnInit, OnDestroy {
  private wasInside = false;
  number = 0;
  opened$: Observable<boolean>;
  myRequests = new Array<INotification>();
  myPendingRequests = new Array<INotification>();
  uuidUser: string;
  isMyRequestsSelected = true;
  subscriptions = new Subscription();
  requestNotifications: { notification: INotification; notificationMessage: INotificationMessage }[];
  pendingRequestNotifications: { notification: INotification; notificationMessage: INotificationMessage }[];
  modalRef: NgbModalRef = null;
  notificationActions = {
    [ActionType.APPROVE]: {
      [NotificationType.CREATE_POLICY]: ({ id }, { notificacionId }) => {
        this.subscriptions.add(this.notificationCenterService.approvePolicy(id, notificacionId).subscribe());
      },
      [NotificationType.SUBSCRIBE_POLICY]: ({ id }, { userFrom, notificacionId }) => {
        this.subscriptions.add(this.notificationCenterService.aproveSubscription(id, userFrom, notificacionId).subscribe());
      },
      [NotificationType.EDIT_POLICY]: ({ id }, { notificacionId }) => {
        this.subscriptions.add(this.notificationCenterService.approveEditPolicy(id, notificacionId).subscribe());
      },
      [NotificationType.EDIT_POLICY_CHANGE]: ({ id }, { notificacionId }) => {
        this.subscriptions.add(this.notificationCenterService.approveEditPolicy(id, notificacionId).subscribe());
      },
      [NotificationType.POLICY_PENDING_DELIST]: ({ id }, { notificacionId }) => {
        this.subscriptions.add(this.notificationCenterService.approveDelistPolicy(id, notificacionId).subscribe());
      },
      [NotificationType.PUBLISH_CONSENT]: ({ id }, { notificacionId }) => {
        this.subscriptions.add(this.notificationCenterService.consentCampaignApprove(id, notificacionId).subscribe());
      },
    },
    [ActionType.CLOSE]: notificationId => {
      this.subscriptions.add(this.requestService.closeRequest(notificationId).subscribe());
    },
    [ActionType.CONTRIBUTE]: {
      [NotificationType.SERVICE_CONTRIBUTION_INVITATION]: ({ id }) => {
        this.router.navigate([`/policies/policy/${id}`]);
      },
      [NotificationType.SERVICE_CONTRIBUTION_ALERT]: ({ id }) => {
        this.router.navigate([`/policies/policy/${id}`]);
      },
    },
    [ActionType.IGNORE]: notificationId => {
      this.subscriptions.add(this.requestService.closeRequest(notificationId).subscribe());
    },
    [ActionType.REJECT]: {
      [NotificationType.CREATE_DATASOURCE]: ({ id }, { notificacionId }) => {
        this.subscriptions.add(this.notificationCenterService.rejectDatasource(id, notificacionId).subscribe());
        this.router.navigate([`/dashboard`]);
      },
      [NotificationType.CREATE_POLICY]: ({ id }, { notificacionId }) => {
        this.subscriptions.add(this.notificationCenterService.rejectPolicy(id, notificacionId).subscribe());
        this.router.navigate([`/dashboard`]);
      },
      [NotificationType.SUBSCRIBE_POLICY]: ({ id }, { userFrom, notificacionId }) => {
        this.subscriptions.add(this.notificationCenterService.rejectSubscription(id, userFrom, notificacionId).subscribe());
      },
      [NotificationType.EDIT_POLICY]: ({ id }, { notificacionId }) => {
        this.subscriptions.add(this.notificationCenterService.rejectEditPolicy(id, notificacionId).subscribe());
      },
      [NotificationType.EDIT_POLICY_CHANGE]: ({ id }, { notificacionId }) => {
        this.subscriptions.add(this.notificationCenterService.rejectEditPolicy(id, notificacionId).subscribe());
      },
      [NotificationType.POLICY_PENDING_DELIST]: ({ id }, { notificacionId }) => {
        this.subscriptions.add(this.notificationCenterService.rejectDelistPolicy(id, notificacionId).subscribe());
      },
      [NotificationType.PUBLISH_CONSENT]: ({ id }, { notificacionId }) => {
        this.subscriptions.add(this.notificationCenterService.consentCampaignReject(id, notificacionId).subscribe());
      },
    },
    [ActionType.REVIEW]: {
      [NotificationType.LICENSE_UPDATED]: ({ id }) => {
        this.router.navigate([`/policies/policy/${id}`]);
      },
      [NotificationType.POLICY_DELIST]: ({ id }) => {
        this.router.navigate([`/policies/policy/${id}`]);
      },
      [NotificationType.EDIT_POLICY_CHANGE]: ({ id }) => {
        this.router.navigate([`/policies/policy/${id}`]);
      },
      [NotificationType.LICNSE_KEY_UPDATE]: _data => {
        this.router.navigate([`/admin/entities`]);
      },
      [NotificationType.EXPIRE_CAMPAIGN]: ({ id }) => {
        this.router.navigate([`/consent-broker/${id}`]);
      },
      [NotificationType.CAMPAIGN_EXPIRED]: ({ id }) => {
        this.router.navigate([`/consent-broker/${id}`]);
      },
      [NotificationType.CAMPAIGN_LIVE]: ({ id }) => {
        this.router.navigate([`/consent-broker/${id}`]);
      },
    },
    [ActionType.UNSUBSCRIBE]: {
      [NotificationType.PRICE_CHANGE_POLICY]: (policy: IPolicy) => {
        this.openUnsubscriptionModal(policy);
      },
    },
    [ActionType.GOTOPOLICY]: {
      [NotificationType.EDIT_POLICY_CHANGE]: ({ id }) => {
        this.router.navigate([`/policies/policy/${id}`]);
      },
    },
  };

  // resourceName comes as a string inside details field:
  // POLICY_PENDING_DELIST
  // SQL_CONTRIBUTION
  // SQL_CONTRIBUTION_ERROR
  // SUBSCRIBER_CONTRIBUTION
  // SUBSCRIBER_CONTRIBUTION_ERROR
  // SUBSCRIPTION_EXPIRE
  resourceNameAsStringCases = [
    NotificationType.POLICY_PENDING_DELIST,
    NotificationType.SQL_CONTRIBUTION,
    NotificationType.SQL_CONTRIBUTION_ERROR,
    NotificationType.SUBSCRIBER_CONTRIBUTION,
    NotificationType.SUBSCRIBER_CONTRIBUTION_ERROR,
    NotificationType.SUBSCRIPTION_EXPIRE,
  ];

  @HostListener('click')
  clickInside() {
    this.wasInside = true;
  }
  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (
      event.target.className !== 'icons__notifications' &&
      !event.target.classList.contains('svg-inline--fa') &&
      event.target.parentElement &&
      !event.target.parentElement.classList.contains('svg-inline--fa') &&
      !event.target.classList.contains('badge') &&
      !this.wasInside
    ) {
      this.notificationCenterService.closeNotifications();
    }
    this.wasInside = false;
  }

  constructor(
    private alertService: AlertService,
    private authService: AuthService,
    private modalService: NgbModal,
    private notificationCenterService: NotificationCenterService,
    private router: Router,
    private requestService: RequestService,
    private translateService: TranslateService
  ) {
    this.router.events.subscribe(ev => {
      if (ev instanceof NavigationEnd) {
        this.notificationCenterService.closeNotifications();
      }
    });
  }

  ngOnInit(): void {
    this.opened$ = this.notificationCenterService.getStateNotifications();
    this.subscriptions.add(
      this.authService.userActAs$.subscribe(([uuid]) => {
        this.uuidUser = uuid;
      })
    );
    this.getRequests();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  get notificationMsgBranches(): any {
    return {
      [PolicyStatus.APPROVED]: {
        [NotificationType.CREATE_DATASOURCE]: ({ resourceName, status, userToName }) =>
          this.getCommonCreateARMSG(resourceName, status, userToName),
        [NotificationType.CREATE_POLICY]: ({ resourceName, status, userToName }) =>
          this.getCommonCreateARMSG(resourceName, status, userToName, true),
        [NotificationType.EDIT_DATASOURCE]: ({ oldName, resourceName, status, userToName }) =>
          this.getCommonEditARMSG(oldName, resourceName, status, userToName),
        [NotificationType.EDIT_POLICY]: ({ oldName, resourceName, status, userToName }) =>
          this.getCommonEditARMSG(oldName, resourceName, status, userToName, true),
        [NotificationType.POLICY_PENDING_DELIST]: ({ resourceName, status, userName }) =>
          this.getCommonARMSG('Request to delist ', resourceName, status, userName),
        [NotificationType.SUBSCRIBE_POLICY]: ({ resourceName, status, userToName }) =>
          this.getCommonARMSG('Subscription to  ', resourceName, status, userToName),
        [NotificationType.SERVICE_CONTRIBUTION_INVITATION]: ({ resourceName, status, userName }) =>
          this.getCommonARMSG('Invitation to contribute to ', resourceName, status, userName),
        [NotificationType.EDIT_POLICY_CHANGE]: ({ description, newFields, newName, oldName }) =>
          this.getEditPolicyChangeResponse(description, newFields, newName, oldName),
      },
      [PolicyStatus.REJECTED]: {
        [NotificationType.CREATE_DATASOURCE]: ({ resourceName, status, userToName }) =>
          this.getCommonCreateARMSG(resourceName, status, userToName),
        [NotificationType.CREATE_POLICY]: ({ resourceName, status, userToName }) =>
          this.getCommonCreateARMSG(resourceName, status, userToName, true),
        [NotificationType.EDIT_DATASOURCE]: ({ oldName, resourceName, status, userToName }) =>
          this.getCommonEditARMSG(oldName, resourceName, status, userToName),
        [NotificationType.EDIT_POLICY]: ({ oldName, resourceName, status, userToName }) =>
          this.getCommonEditARMSG(oldName, resourceName, status, userToName, true),
        [NotificationType.POLICY_PENDING_DELIST]: ({ resourceName, status, userName }) =>
          this.getCommonARMSG('Request to delist ', resourceName, status, userName),
        [NotificationType.SUBSCRIBE_POLICY]: ({ resourceName, status, userToName }) =>
          this.getCommonARMSG('Subscription to  ', resourceName, status, userToName),
        [NotificationType.SERVICE_CONTRIBUTION_INVITATION]: ({ resourceName, status, userName }) =>
          this.getCommonARMSG('Invitation to contribute to ', resourceName, status, userName),
      },
      [PolicyStatus.PENDING]: {
        [NotificationType.CREATE_DATASOURCE]: ({ resourceName }) => this.getCommonCreateMSG(resourceName),
        [NotificationType.CREATE_POLICY]: ({ resourceName }) => this.getCommonCreateMSG(resourceName, 'Policy'),
        [NotificationType.PUBLISH_CONSENT]: ({ resourceName }) => this.getCommonCreateMSG(resourceName, 'Consent Campaign'),
        [NotificationType.EDIT_DATASOURCE]: ({ resourceName }) => this.getCommonEditMSG(resourceName),
        [NotificationType.EDIT_POLICY]: ({ oldName, resourceName }) => {
          const name = oldName || resourceName;
          return this.getCommonEditMSG(name, 'Policy');
        },
        [NotificationType.POLICY_PENDING_DELIST]: ({ resourceName }) => ({
          message: `Your Policy <strong>${resourceName}</strong> is pending for delisting.`,
          options: null,
        }),
        [NotificationType.SUBSCRIBE_POLICY]: ({ resourceName }) => ({
          message: `Your subscription to <strong>${resourceName}</strong> is pending.`,
          options: null,
        }),
        [NotificationType.SERVICE_CONTRIBUTION_INVITATION]: ({ resourceName, userName }) => ({
          message: `Your invitation for ${userName} to contribute to <strong>${resourceName}</strong> is pending.`,
          options: null,
        }),
        [NotificationType.EDIT_POLICY_CHANGE]: ({ description, newFields, newName, oldName }) =>
          this.getEditPolicyChangeResponse(description, newFields, newName, oldName),
      },
    };
  }

  get commonNotificationMsgBranches(): any {
    return {
      [NotificationType.SERVICE_CONTRIBUTION_ALERT]: ({ resourceName, serviceName }) => ({
        title: serviceName,
        message: `Data has not been uploaded to <strong>${resourceName}</strong>.`,
        options: ['Contribute', 'Ignore'],
      }),
      [NotificationType.PRICE_CHANGE_POLICY]: ({ resourceName }) => ({
        message: ` <strong>${resourceName}</strong> subscription price has been changed.`,
        options: ['Unsubscribe', 'Close'],
      }),
      [NotificationType.LICENSE_UPDATED]: ({ resourceName }) => ({
        message: `The legal terms of <strong>${resourceName}</strong> has been updated.`,
        options: ['Review', 'Close'],
      }),
      [NotificationType.SQL_CONTRIBUTION]: ({ resourceName }) => ({
        message: `A new contribution to <strong>${resourceName}</strong> has been uploaded.`,
      }),
      [NotificationType.SQL_CONTRIBUTION_ERROR]: ({ resourceName }) => ({
        message: `There has been a error contributing to <strong>${resourceName}</strong>.`,
      }),
      [NotificationType.SUBSCRIBER_CONTRIBUTION]: ({ resourceName }) => ({
        message: `A new contribution to <strong>${resourceName}</strong> has been uploaded.`,
      }),
      [NotificationType.SUBSCRIBER_CONTRIBUTION_ERROR]: ({ resourceName }) => ({
        message: `There has been a error contributing to  <strong>${resourceName}</strong>`,
      }),
      [NotificationType.SUBSCRIPTION_EXPIRE]: ({ resourceName }) => ({
        title: null,
        message: this.translateService.instant('notifications.SUBSCRIPTION_EXPIRE', { resourceName }),
      }),
      [NotificationType.DELETE_POLICY]: ({ resourceName, deleteBy }) => ({
        message: `${this.translateService.instant('notifications.DELETED', {
          object_type: this.translateService.instant('graph.policy'),
          resourceName,
          deleteBy,
        })}`,
        options: ['Close'],
      }),
      [NotificationType.DELETE_DATASOURCE]: ({ resourceName, deleteBy }) => ({
        message: `${this.translateService.instant('notifications.DELETED', {
          object_type: this.translateService.instant('graph.datasource'),
          resourceName,
          deleteBy,
        })}`,
        options: ['Close'],
      }),

      [NotificationType.REVOKE_SUBSCRIPTION]: ({ reason, resourceName }) => ({
        message: `${this.translateService.instant('notifications.POLICY_REVOKE_SUSCRIPTER')}
          ${resourceName},
          ${this.translateService.instant('notifications.POLICY_REVOKE_SUSCRIPTER_AFTER', { reason })}`,
      }),
      [NotificationType.POLICY_UNSUBSCRIBE]: ({ newName }) => {
        return {
          message: `The policies for <strong>${newName}</strong> have changed and the data will no longer be available for you. This change will be taken into effect immediately.`,
          options: ['Close'],
        };
      },
      [NotificationType.REASSIGN_USER_GROUPS]: ({ newValues }) => {
        return {
          message: this.translateService.instant('notifications.Group Update', { newValues }),
          options: ['Close'],
        };
      },
      [NotificationType.REASSIGN_USER_ENTITLEMENTS]: ({ newValues }) => {
        return {
          message: this.translateService.instant('notifications.Entitlements Update', { newValues }),
          options: ['Close'],
        };
      },
      [NotificationType.LICNSE_KEY_UPDATE]: _data => ({
        message: `Your organization license has been updated.`,
        options: ['Review', 'Close'],
      }),
      [NotificationType.EXPIRE_CAMPAIGN]: ({ resourceName, daysBeforeExpire, expirationDate }) => ({
        title: this.translateService.instant('Data expiration'),
        message: this.translateService.instant('notifications.EXPIRE_CAMPAIGN', {
          campaignName: resourceName,
          daysBeforeExpire,
          expirationDate,
        }),
        options: ['Review'],
      }),
      [NotificationType.CAMPAIGN_EXPIRED]: ({ resourceName }) => ({
        title: this.translateService.instant('Data expiration'),
        message: this.translateService.instant('notifications.CAMPAIGN_EXPIRED', {
          resourceName,
        }),
        options: ['Review'],
      }),
      [NotificationType.CAMPAIGN_LIVE]: ({ resourceName }) => ({
        title: this.translateService.instant('Your campaign is now live'),
        message: this.translateService.instant('notifications.CAMPAIGN_LIVE', {
          campaignName: resourceName,
        }),
        options: ['Review'],
      }),
      [NotificationType.EXPIRE_POLICY]: ({ oldPolicyName, expirationDate, newPolicyName }) => ({
        title: this.translateService.instant('Policy expiring soon'),
        message: this.translateService.instant('notifications.EXPIRE_POLICY', {
          policyName: oldPolicyName,
          expirationDate,
          newPolicyName: newPolicyName,
        }),
        options: ['Close'],
      }),
      [NotificationType.POLICY_EXPIRED]: ({ policyName }) => ({
        title: this.translateService.instant('Data expiration'),
        message: this.translateService.instant('notifications.POLICY_EXPIRED', {
          resourceName: policyName,
        }),
        options: ['Close'],
      }),
    };
  }

  get pendinNotificationMsgBranches(): any {
    return {
      [NotificationType.CREATE_DATASOURCE]: ({ userName, resourceName }) => this.getCommonPendingMessage(userName, resourceName),
      [NotificationType.EDIT_DATASOURCE]: ({ userName, resourceName }) => this.getCommonPendingMessage(userName, resourceName),
      [NotificationType.CREATE_POLICY]: ({ userName, resourceName }) => this.getCommonPendingMessage(userName, resourceName, 'Policy'),
      [NotificationType.PUBLISH_CONSENT]: ({ userName, resourceName }) =>
        this.getCommonPendingMessage(userName, resourceName, 'Consent Campaign'),
      [NotificationType.EDIT_POLICY]: ({ oldName, newFields, newName, resourceName }) => {
        const name = oldName || resourceName;
        let message;
        const options = ['Approve', 'Reject'];
        if (newFields) {
          message = `<strong>${name}</strong>'s rules has been updated.`;
        } else {
          message =
            oldName !== newName
              ? `<strong>${name}</strong>'s was renamed to ${newName} and/or ${name}'s description was changed.`
              : `<strong>${name}</strong>'s description was changed.`;
        }
        return {
          message,
          options,
        };
      },
      [NotificationType.POLICY_PENDING_DELIST]: ({ userName, resourceName }) => ({
        message: `${userName} requests Policy <strong>${resourceName}</strong> to be delisted.`,
        options: ['Approve', 'Reject'],
      }),
      [NotificationType.SUBSCRIBE_POLICY]: ({ userName, resourceName }) => ({
        message: `${userName} requests subscription to <strong>${resourceName}</strong>`,
        options: ['Approve', 'Reject'],
      }),
      [NotificationType.SERVICE_CONTRIBUTION_INVITATION]: ({ userName, resourceName }) => ({
        message: `${userName} invites you to contribute to <strong>${resourceName}</strong>`,
        options: ['Contribute', 'Reject'],
      }),
      [NotificationType.POLICY_DELIST]: ({ resourceName, expirationDate }) => ({
        message: `${this.translateService.instant('notifications.POLICY_DELIST')}
        <strong>${resourceName}</strong> ${this.translateService.instant('notifications.POLICY_DELIST_AFTER', {
          endDate: expirationDate,
        })}`,
        options: ['Review', 'Close'],
      }),
    };
  }

  clearAll = (): void => {
    this.subscriptions.add(this.requestService.clearAllRequests().subscribe());
  };

  getCommonPendingMessage = (userName: string, resourceName: string, type = 'Datasource') => ({
    message: `${userName} requests ${type} <strong>${resourceName}</strong> to be reviewed.`,
    options: ['Approve', 'Reject'],
  });

  getCommonCreateMSG = (resourceName, type = 'Datasource') => ({
    message: `Your ${type} <strong>${resourceName}</strong> creation is pending.`,
    options: null,
  });

  getCommonCreateARMSG = (resourceName, status, userName, isPolicy = false) => ({
    message: `Creation of ${isPolicy ? 'Policy' : 'Datasource'} <strong>${resourceName}</strong> ${status.toLowerCase()} by ${userName}`,
    options: ['Close'],
  });

  getCommonEditMSG = (resourceName, type = 'Datasource') => ({
    message: `Your ${type} <strong>${resourceName}</strong> update is pending.`,
    options: null,
  });

  getCommonEditARMSG = (oldName, resourceName, status, userName, isPolicy = false) => {
    return {
      message: `Changes for ${isPolicy ? 'Policy' : 'Datasource'} <strong>${
        resourceName || oldName
      }</strong> ${status.toLowerCase()} by ${userName}`,
      options: ['Close'],
    };
  };

  getCommonARMSG = (beforeLabel, resourceName, status, userName) => ({
    message: `${beforeLabel} <strong>${resourceName}</strong> ${status.toLowerCase()} by ${userName}`,
    options: ['Close'],
  });

  getEditPolicyChangeResponse = (description, newFields, newName, oldName) => {
    let message;
    let options = ['Go to Policy', 'Close'];
    if (newFields) {
      message = `The number of data points you subscribed to has changed. Please review Policy <strong>${oldName}</strong> for details. This change will be taken into effect immediately.`;
      options = ['Review', 'Close'];
    } else {
      const partial = description ? 'and its description has changed' : '';
      message =
        oldName && newName && oldName !== newName
          ? `${oldName} was renamed to <strong>${newName}</strong> ${partial}.`
          : `<strong>${oldName || newName}</strong>'s description has been changed.`;
    }

    return {
      message,
      options,
    };
  };

  getTime(date: number): string {
    const notificationDate = moment(date);
    const minutes = Math.round(Math.abs(moment.duration(notificationDate.diff(moment())).asMinutes()));
    const seconds = Math.round(Math.abs(moment.duration(notificationDate.diff(moment())).asSeconds()));
    const hours = Math.round(Math.abs(moment.duration(notificationDate.diff(moment())).asHours()));
    const days = Math.round(Math.abs(moment.duration(notificationDate.diff(moment())).asDays()));

    if (seconds >= 60) {
      if (minutes >= 60) {
        if (hours >= 24) {
          return `${days}d`;
        } else {
          return `${hours}h`;
        }
      } else {
        return `${minutes}m`;
      }
    } else {
      return `${seconds}s`;
    }
  }

  goToResource = (notification: INotification): void => {
    const { status, type, resourceId } = notification;
    if (status.toLowerCase() !== Status.REJECTED.toLowerCase()) {
      switch (type.toUpperCase()) {
        case NotificationType.SUBSCRIBE_POLICY:
        case NotificationType.SERVICE_CONTRIBUTION_ALERT:
          this.router.navigate([`/policies/policy/${resourceId}`]);
          break;
        case NotificationType.CREATE_POLICY:
        case NotificationType.EDIT_POLICY:
        case NotificationType.EDIT_POLICY_CHANGE:
        case NotificationType.PRICE_CHANGE_POLICY:
        case NotificationType.SERVICE_CONTRIBUTION_INVITATION:
        case NotificationType.LICENSE_UPDATED:
        case NotificationType.SUBSCRIPTION_EXPIRE:
        case NotificationType.POLICY_DELIST:
        case NotificationType.POLICY_PENDING_DELIST:
        case NotificationType.SUBSCRIBER_CONTRIBUTION:
        case NotificationType.POLICY_UNSUBSCRIBE:
        case NotificationType.EXPIRE_POLICY:
          this.router.navigate([`/policies/policy/${resourceId}`]);
          break;
        case NotificationType.POLICY_EXPIRED:
        case NotificationType.CREATE_DATASOURCE:
        case NotificationType.SQL_CONTRIBUTION:
          this.router.navigate([`/datasets/${resourceId}`]);
          break;
        case NotificationType.CAMPAIGN_LIVE:
        case NotificationType.EXPIRE_CAMPAIGN:
        case NotificationType.CAMPAIGN_EXPIRED:
          this.router.navigate([`/consent-broker/${resourceId}`]);
          break;
      }
    }
  };

  getRequestDetails = (notification: INotification): any => {
    const { details, type, resourceId } = notification;
    let resourceName;
    let requestDetails;
    try {
      const parsedDetails = notification.details ? JSON.parse(notification.details) : null;
      const {
        applicationName,
        name,
        daysBeforeExpire,
        description,
        newPolicyName,
        oldPolicyName,
        expirationDate,
        newFields,
        newName,
        newValues,
        oldName,
        reason,
        deleteBy,
        title,
        policyName,
      } = parsedDetails;
      const serviceName = parsedDetails.serviceName;
      resourceName = name || applicationName || title;
      requestDetails = {
        resourceName,
        serviceName,
        reason,
        expirationDate,
        newPolicyName,
        oldPolicyName,
        newFields,
        oldName,
        newName,
        description,
        deleteBy,
        newValues,
        daysBeforeExpire,
        policyName,
      };
    } catch {
      // SERVICE_CONTRIBUTION_ALERT returns just a string with the name of the datasource
      resourceName = this.resourceNameAsStringCases.includes(type) ? details : resourceId;
      requestDetails = { resourceName };
    }
    return requestDetails;
  };

  getRequestMessage = (notification: INotification, isPendingRequest = false): INotificationMessage => {
    const { status, timeStamp, type, userFromName, userToName } = notification;
    let messages = this.pendinNotificationMsgBranches;
    if (!isPendingRequest) {
      messages = this.commonNotificationMsgBranches[type] ? this.commonNotificationMsgBranches : this.notificationMsgBranches[status];
    }
    const requestDetails = this.getRequestDetails(notification);
    const {
      description,
      newFields,
      newName,
      oldName,
      reason,
      resourceName,
      serviceName,
      deleteBy,
      newValues,
      daysBeforeExpire,
      expirationDate,
      oldPolicyName,
      newPolicyName,
      policyName,
    } = requestDetails;
    const message: INotificationMessage = {
      title: resourceName || oldName,
      date: this.getTime(timeStamp as number),
      options: ['Close'],
      message: null,
    };
    const customOptions =
      messages && messages[type]
        ? messages[type]({
            expirationDate,
            description,
            reason,
            resourceName: resourceName || oldName,
            serviceName,
            status,
            userName: userFromName,
            userToName,
            oldName,
            newName,
            newFields,
            deleteBy: deleteBy || '',
            newValues,
            daysBeforeExpire,
            oldPolicyName,
            newPolicyName,
            policyName,
          })
        : {};
    return { ...message, ...customOptions };
  };

  getRequests = (): void => {
    this.subscriptions.add(
      this.requestService.requests$.subscribe((requests: INotification[]) => {
        if (!requests) {
          return;
        }
        this.myRequests = requests;
        this.requestNotifications = this.myRequests.map(request => ({
          notificationMessage: this.getRequestMessage(request),
          notification: request,
        }));
      })
    );
    this.subscriptions.add(
      this.requestService.pendingRequests$.subscribe((pendingRequests: INotification[]) => {
        if (!pendingRequests) {
          return;
        }
        this.myPendingRequests = pendingRequests;
        this.pendingRequestNotifications = this.myPendingRequests.map(request => ({
          notificationMessage: this.getRequestMessage(request, true),
          notification: request,
        }));
      })
    );
    this.requestService.getRequests();
  };

  listMyRequests = (): void => {
    this.isMyRequestsSelected = true;
  };

  listMyPendingRequests = (): void => {
    this.isMyRequestsSelected = false;
  };

  openUnsubscriptionModal = (policyData: IPolicy): void => {
    this.modalRef = this.modalService.open(UnsubscribePolicyModalComponent);
    this.modalRef.componentInstance.policy = policyData;
    this.subscriptions.add(this.modalRef.componentInstance.mainClick.subscribe(this.unsubscribe));
  };

  reloadComponent() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([this.router.url]);
  }

  triggerAction = (action: INotificationAction): void => {
    const { actionType, notification } = action;
    try {
      const { details, id, type, userFrom, userTo, resourceId } = notification;
      const resource: IDataSource | IPolicy = typeof details !== 'object' ? { id: resourceId } : JSON.parse(details);
      if (actionType === ActionType.CLOSE || actionType === ActionType.IGNORE) {
        this.notificationActions[actionType](id);
      } else {
        this.notificationActions[actionType][type](resource, { userFrom, userTo, notificacionId: id });
      }
    } catch (error) {
      this.alertService.error('Something went wrong processing notifications');
    }
  };

  unsubscribe = (policy: IPolicy): void => {
    this.subscriptions.add(
      this.notificationCenterService.policyUnsubscribe(policy).subscribe(() => {
        this.modalRef.close();
        this.modalRef = null;
        const message = `${this.translateService.instant('Your subscription has been ended.')}`;
        this.alertService.success(message);
        setTimeout(() => {
          this.reloadComponent();
        }, 1500);
      })
    );
  };
}
